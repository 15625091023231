

.container {
    width: 80vw;
    background-color: #fff;
    margin: 5rem auto;
    border-radius: 3rem;
    overflow: hidden;
    box-shadow: var(--box-shadow) ;
    position: relative;

    @media only screen and (max-width: 800px){
        margin: 0 0 15rem 0;
        border-radius: 0;
        width: 100%;
    }
    //test
    // min-height: 150rem;
}

.header {
    position: relative;
    width: 100%;
    height: 80vh;
    background-image: url('/img/header_img.jpg');
    background-position: 0 83%;
    @media only screen and (max-width: 1300px){
        background-position: 12% 83%;
    }
    @media only screen and (max-width: 1100px){
        background-position: 25% 83%;
    }
    @media only screen and (max-height: 650px){
       background-position: 12% 70%;
     }
    @media only screen and (max-height: 550px){
       background-position: 12% 65%;
     }
    @media only screen and (max-height: 440px){
       background-position: center;
     }
}
.main {
    position: relative;
}

.about {
    margin-top: 5rem;
    padding: 0 5rem;
}

.contact {
    margin-top: 5rem;
    padding: 0 5rem 5rem 5rem;
    background-color: black;
    color: var(--color-primary);
    color: #fff;
}


/////////////////////////////
/// SZECHÉNYI 2020 oldal
.palyazat {
    position: relative;
    z-index: 3;
    padding: 8rem;
    font-size: 1.8rem;
    letter-spacing: 1.2;
    margin-top: 18rem;

    & h2 {
        text-align: center;
        font-size: 4rem;
        font-weight: 900;
        margin-bottom: 8rem;
    }

    & h3 {
        margin-top: 2rem;
    }

    &-list {
        margin: 1rem 0 2rem 3rem;
        list-style-type: '- ';
    }
    &-text p {
        letter-spacing: 1.2;
        &:not(:last-child) {
            margin-bottom: 1.4rem;
        }
    }
}
.sajto {
    padding: 8rem;
    font-size: 1.8rem;

    & h2 {
        text-align: center;
        font-size: 4rem;
        font-weight: 900;
        margin-bottom: 8rem;
    }

    & h3 {
        margin-top: 2rem;
    }

    & p {
        padding: 0 5rem 0 5rem;
    }

    &-box {
        padding: 2rem 5rem;
        margin: 3rem;
        border: 1px solid black;
    }
    &-info {
        margin-left: 5rem;
    }
}
.letolt {
    margin-top: 4rem;
}