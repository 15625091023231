@keyframes NavigationIn {
  0% {
    opacity: 0;
    transform: translateY(-5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes headlineTextInLeft {
  0% {
    opacity: 0;
    transform: translateX(-5rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes headlineButtonUp {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes infoboxUp {
  0% {
    opacity: 0;
    transform: translateY(3rem)translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0)translateX(-50%);
  }
}

:root {
  --color-primary: #e0212f;
  --color-secondary: lightgreen;
  --box-shadow: 0 4rem 4rem #0006;
  --box-shadow-small: 0 2rem 2rem #0000004d;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 55%;
  }
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 45%;
  }
}

body {
  background-color: var(--color-primary);
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 1.6;
}

.header-secondary {
  text-transform: uppercase;
  color: var(--color-primary);
  letter-spacing: 1.2px;
  font-size: 5rem;
  font-weight: 800;
}

.header-primary {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--color-primary);
  font-size: 3.8rem;
}

@media only screen and (max-width: 800px) {
  .header-primary {
    font-size: 3.5rem;
  }
}

@media only screen and (max-height: 800px) {
  .header-primary {
    line-height: 1.2;
  }
}

@media only screen and (max-height: 650px) {
  .header-primary {
    font-size: 3rem;
  }
}

.headline {
  width: 35%;
  max-height: 70vh;
  color: var(--color-primary);
  margin-left: 7rem;
  padding-left: 2rem;
  animation: 1.5s ease-in-out 1s backwards headlineTextInLeft;
  position: relative;
  top: 10rem;
}

@media only screen and (max-width: 1600px) {
  .headline {
    width: 40%;
    width: 45%;
  }
}

.headline-slogen {
  margin: 3rem 0;
  font-size: 2.5rem;
}

@media only screen and (max-height: 800px) {
  .headline-slogen {
    line-height: 1.2;
  }
}

@media only screen and (max-height: 700px) {
  .headline-slogen {
    margin: 1rem 0;
    line-height: 1.2;
  }
}

@media only screen and (max-height: 650px) {
  .headline-slogen {
    line-height: 1;
    top: 7rem;
  }
}

.headline-button {
  background-color: var(--color-secondary);
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: var(--box-shadow-small);
  border: none;
  border-radius: 100rem;
  padding: 2rem 4rem;
  font-weight: 700;
  transition: transform .3s;
  animation: .5s ease-out 2.5s backwards headlineButtonUp;
}

@media only screen and (max-width: 800px) {
  .headline-button {
    margin-top: 6rem;
  }
}

.headline-button:hover {
  transform: translateY(-.3rem)scale(1.05);
}

.headline-button:active {
  transform: scale(.95);
}

.infoblokk {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  right: .5rem;
}

.infoblokk-img {
  min-height: 150px;
  height: 10vh;
}

.palyazat-infoblokk {
  opacity: .8;
  position: absolute;
  top: 5rem;
  right: 0;
}

.palyazat-infoblokk__img {
  height: 40rem;
}

@media only screen and (max-width: 600px) {
  .palyazat-infoblokk__img {
    height: 30rem;
    min-height: 150px;
  }
}

.navigation {
  height: 7rem;
  width: 90%;
  background-color: #fff;
  align-items: center;
  margin: auto;
  padding: 0 2rem;
  animation: 1s ease-in .5s backwards NavigationIn;
  display: flex;
  position: relative;
  top: 5rem;
}

@media only screen and (max-width: 700px) {
  .navigation {
    z-index: 5;
    box-shadow: var(--box-shadow-small);
    border-radius: 1rem;
    position: fixed;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 400px) {
  .navigation {
    width: 100%;
  }
}

.navigation-scroll {
  z-index: 5;
  box-shadow: var(--box-shadow-small);
  border-radius: 1rem;
  position: fixed;
  top: .5rem;
  left: 50%;
  transform: translateX(-50%);
}

.navigation-brand {
  height: 80%;
}

.navigation-items {
  flex: 0 0 70%;
  justify-content: space-around;
  align-self: stretch;
  margin: 0 auto;
  list-style: none;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .navigation-items {
    flex: 0 0 90%;
  }
}

.navigation-item {
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: var(--color-primary);
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.4rem;
  font-weight: 700;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  .navigation-item {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 500px) {
  .navigation-item {
    font-size: 1.1rem;
  }
}

.navigation-item a, .navigation-item a:focus, .navigation-item a:active {
  z-index: 2;
  height: 100%;
  color: inherit;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.navigation-item:before {
  content: "";
  background-color: var(--color-secondary);
  height: 100%;
  width: 100%;
  z-index: 1;
  transform-origin: bottom;
  transition: transform .3s .3s, left .3s;
  display: inline-block;
  position: absolute;
  left: -110%;
  transform: scaleY(10%);
}

.navigation-item:hover:before {
  left: 0;
  transform: scale(1);
}

#nav-palyazat {
  z-index: 3;
  background-color: #d3d3d3;
  border-radius: 2rem;
  animation: none;
  top: 1rem;
}

.infobox {
  width: 75%;
  box-shadow: var(--box-shadow-small);
  background-color: #fff;
  border-radius: 1rem;
  justify-content: space-between;
  animation: 1s ease-in 1.5s backwards infoboxUp;
  display: flex;
  position: relative;
  top: -8rem;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1000px) {
  .infobox {
    width: 85%;
  }
}

@media only screen and (max-width: 700px) {
  .infobox {
    width: 98%;
    display: none;
  }
}

@media only screen and (max-height: 550px) {
  .infobox {
    display: none;
  }
}

.infobox-item {
  text-align: center;
  flex-direction: column;
  flex: 0 0 33%;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.infobox-item:not(:last-child) {
  border-right: 1px solid var(--color-primary);
}

.infobox-icon {
  height: 6rem;
  width: 6rem;
  fill: var(--color-primary);
  margin-bottom: 1rem;
}

.infobox-text {
  letter-spacing: 1.1px;
  font-size: 2rem;
  font-weight: 600;
}

@media only screen and (max-width: 1600px) {
  .infobox-text {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 1300px) {
  .infobox-text {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .infobox-text {
    font-size: 1.4rem;
  }
}

.about__part {
  align-items: center;
  display: flex;
}

.about__part:nth-child(odd) {
  flex-direction: row-reverse;
}

.about__part:not(:last-child) {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 1100px) {
  .about__part, .about__part:nth-child(odd) {
    flex-direction: column;
  }
}

.about-text {
  letter-spacing: 1.3px;
  align-items: center;
  padding: 2rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2;
  display: flex;
}

@media only screen and (max-width: 1300px) {
  .about-text {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 1100px) {
  .about-text {
    font-size: 1.8rem;
  }
}

.about-image {
  max-width: 50%;
  border-radius: 5rem;
  margin: 1rem;
  padding: 2rem;
}

@media only screen and (max-width: 1100px) {
  .about-image {
    max-width: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .about-image {
    max-width: 95%;
  }
}

.contact-content {
  color: #fff;
  justify-content: space-evenly;
  font-size: 1.8rem;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .contact-content {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 600px) {
  .contact-content {
    padding-bottom: 5rem;
    font-size: 1.4rem;
  }
}

.contact-content h3 {
  margin-bottom: 1.8rem;
}

.contact-content__email p {
  display: inline-block;
}

.contact-content__email div {
  align-items: center;
  display: flex;
}

.contact-content__email div:not(:last-child) {
  margin-bottom: 1rem;
}

.contact-content__icon {
  height: 3rem;
  width: 3rem;
  fill: currentColor;
  margin-right: 1.5rem;
  display: inline-block;
}

.container {
  width: 80vw;
  box-shadow: var(--box-shadow);
  background-color: #fff;
  border-radius: 3rem;
  margin: 5rem auto;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 800px) {
  .container {
    width: 100%;
    border-radius: 0;
    margin: 0 0 15rem;
  }
}

.header {
  width: 100%;
  height: 80vh;
  background-image: url("header_img.2da3171c.jpg");
  background-position: 0 83%;
  position: relative;
}

@media only screen and (max-width: 1300px) {
  .header {
    background-position: 12% 83%;
  }
}

@media only screen and (max-width: 1100px) {
  .header {
    background-position: 25% 83%;
  }
}

@media only screen and (max-height: 650px) {
  .header {
    background-position: 12% 70%;
  }
}

@media only screen and (max-height: 550px) {
  .header {
    background-position: 12% 65%;
  }
}

@media only screen and (max-height: 440px) {
  .header {
    background-position: center;
  }
}

.main {
  position: relative;
}

.about {
  margin-top: 5rem;
  padding: 0 5rem;
}

.contact {
  color: var(--color-primary);
  color: #fff;
  background-color: #000;
  margin-top: 5rem;
  padding: 0 5rem 5rem;
}

.palyazat {
  z-index: 3;
  letter-spacing: 1.2px;
  margin-top: 18rem;
  padding: 8rem;
  font-size: 1.8rem;
  position: relative;
}

.palyazat h2 {
  text-align: center;
  margin-bottom: 8rem;
  font-size: 4rem;
  font-weight: 900;
}

.palyazat h3 {
  margin-top: 2rem;
}

.palyazat-list {
  margin: 1rem 0 2rem 3rem;
  list-style-type: "- ";
}

.palyazat-text p {
  letter-spacing: 1.2px;
}

.palyazat-text p:not(:last-child) {
  margin-bottom: 1.4rem;
}

.sajto {
  padding: 8rem;
  font-size: 1.8rem;
}

.sajto h2 {
  text-align: center;
  margin-bottom: 8rem;
  font-size: 4rem;
  font-weight: 900;
}

.sajto h3 {
  margin-top: 2rem;
}

.sajto p {
  padding: 0 5rem;
}

.sajto-box {
  border: 1px solid #000;
  margin: 3rem;
  padding: 2rem 5rem;
}

.sajto-info {
  margin-left: 5rem;
}

.letolt {
  margin-top: 4rem;
}

/*# sourceMappingURL=index.a7728050.css.map */
