
@keyframes NavigationIn {
    0% {
        opacity: 0;
        transform: translateY(-5rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes headlineTextInLeft {
    0% {
        opacity: 0;
        transform: translateX(-5rem);
    }
    80% {
        transform: translateX(1rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes headlineButtonUp {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes infoboxUp {
    0% {
        opacity: 0;
        transform: translateY(3rem) translateX(-50%);;
    }
    100% {
        opacity: 1;
        transform: translateY(0) translateX(-50%);;
    }
}