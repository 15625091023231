////////////////////////////////
/// HEADLINES

.header-secondary {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 5rem;
    color: var(--color-primary);
    letter-spacing: 1.2;
}

.header-primary {
    text-transform: uppercase;
    font-size: 3.8rem;
    letter-spacing: 1;
    color: var(--color-primary);
    @media only screen and (max-width: 800px){
        font-size: 3.5rem;
    }
    @media only screen and (max-height: 800px){
       line-height: 1.2;
    }
    @media only screen and (max-height: 650px){
       font-size: 3rem;
    }
}

.headline {
    animation: headlineTextInLeft 1.5s ease-in-out 1s backwards; //iteration-count direction fill-mode;
    width: 35%;
    max-height: 70vh;
    margin-left: 7rem;
    padding-left: 2rem;
    position: relative;
    top: 10rem;
    color: var(--color-primary);

    @media only screen and (max-width: 1600px){
        width: 40%;
    }
    @media only screen and (max-width: 1600px){
        width: 45%;
    }

    &-slogen {
        margin: 3rem 0;
        font-size: 2.5rem;
        @media only screen and (max-height: 800px){
            line-height: 1.2;
         }
        @media only screen and (max-height: 700px){
            line-height: 1.2;
            margin: 1rem 0;
         }
        @media only screen and (max-height: 650px){
            line-height: 1;
            top: 7rem;
         }
    }

    &-button {
        animation: headlineButtonUp .5s ease-out 2.5s backwards; // iteration-count direction fill-mode;
        
        border: none;
        background-color: var(--color-secondary);
        text-transform: uppercase;
        font-weight: 700;
        padding: 2rem 4rem;
        border-radius: 100rem;
        cursor: pointer;
        box-shadow: var(--box-shadow-small);
        transition: transform .3s;
        @media only screen and (max-width: 800px){
            margin-top: 6rem;
        }

        &:hover {
            transform: translateY(-.3rem) scale(1.05);
        }
        &:active {
            transform: scale(0.95);
        }

    }
}


////////////////////////////////
/// INFOBLOCK

.infoblokk {
    position: fixed;
    right: .5rem;
    bottom: 0;
    z-index: 1000;
  
    
    &-img {
        min-height: 150px;
        height: 10vh;
    }
}

.palyazat-infoblokk {
    position: absolute;
    right: 0;
    top: 5rem;
    opacity: .8;

    &__img {
        height: 40rem;
        @media only screen and (max-width: 600px){
            height: 30rem;
            min-height: 150px;
        }
    }
}

////////////////////////////////
/// NAVIGATION

.navigation {
    height: 7rem;
    width: 90%;
    background-color: #fff;
    margin: auto;
    display: flex;
    padding: 0 2rem;
    align-items: center;
    position: relative;
    top: 5rem;
    animation: NavigationIn 1s ease-in .5s backwards; // delay iteration-count direction fill-mode;

    @media only screen and (max-width: 700px) {
        top: .5rem;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        box-shadow: var(--box-shadow-small);
        border-radius: 1rem;
    }
    @media only screen and (max-width: 400px) {
        width: 100%;
    }
    
    
    &-scroll {
        // @include nav-sec;
        top: .5rem;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        box-shadow: var(--box-shadow-small);
        border-radius: 1rem;
    }

    &-brand {
        height: 80%;
    }

    &-items {
        list-style: none;
        display: flex;
        justify-content: space-around;
        flex: 0 0 70%;
        margin: 0 auto;
        align-self: stretch;
        // align-items: center;
        
        @media only screen and (max-width: 600px) {
            flex: 0 0 90%;
        }
        
        
    }

    &-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.4rem;
        letter-spacing: 1.2;
        padding: 0 2rem;
        overflow: hidden;
        color: var(--color-primary);

        @media only screen and (max-width: 1000px){
            font-size: 1.2rem;
        }

        @media only screen and (max-width: 500px) {
            font-size: 1.1rem;
        }

        & a, a:focus, a:active {
            position: relative;
            z-index: 2;
            height: 100%;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: inherit;
            
        }
    }

    &-item::before {
        position: relative;
        left: -110%;
        content: '';
        display: inline-block;
        background-color: var(--color-secondary);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        transform: scaleY(10%);
        transform-origin: bottom;
        transition: transform .3s .3s, left .3s;
    }

    &-item:hover::before{
        transform:scale(100%);
        left: 0;
    }
}

#nav-palyazat {
    background-color: lightgrey;
    border-radius: 2rem;
    animation: none;
    z-index: 3;
    top: 1rem;
}

////////////////////////////////
/// INFOBOX

.infobox {
    animation: infoboxUp 1s ease-in 1.5s backwards;
    position: relative;
    left: 50%;
    top: -8rem;
    width: 75%;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: var(--box-shadow-small);
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1000px){
        width: 85%;
    }
    @media only screen and (max-width: 700px){
        width: 98%;
    }
    @media only screen and (max-width: 700px){
        display: none;
    }
    @media only screen and (max-height: 550px){
        display: none;
     }

    &-item {
        flex: 0 0 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1rem;
        &:not(:last-child) {
            border-right: 1px solid var(--color-primary);

        }
    }

    &-icon {
        height: 6rem;
        width: 6rem;
        fill: var(--color-primary);
        margin-bottom: 1rem;
    }

    &-text {
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 1.1;

        @media only screen and (max-width: 1600px){
            font-size: 1.6rem;
        }
        @media only screen and (max-width: 1300px){
            font-size: 1.5rem;
        }
        @media only screen and (max-width: 1200px){
            font-size: 1.4rem;
        }
    }
}

////////////////////////////////
/// ABOUT

.about {
    &__part {
        display: flex;
        align-items: center;
        &:nth-child(odd) {
            flex-direction: row-reverse;
        }
    
        &:not(:last-child) {
            margin-bottom: 5rem;
        }
        @media only screen and (max-width: 1100px){
            flex-direction: column;
            &:nth-child(odd) {
                flex-direction: column;
            }
        }    
    }   

    &-text {
        
        display: flex;
        align-items: center;
        padding: 2rem;
        font-size: 1.8rem;
        font-weight: 400;
        letter-spacing: 1.3;
        line-height: 2;
        @media only screen and (max-width: 1300px){
            font-size: 1.6rem;
        }
        @media only screen and (max-width: 1100px){
            font-size: 1.8rem;
        }
       
    }

    &-image {
       
        max-width: 50%;
        margin: 1rem;
        padding: 2rem;
        border-radius: 5rem;
        @media only screen and (max-width: 1100px){
            max-width: 80%;
        }
        @media only screen and (max-width: 900px){
            max-width: 95%;
        }
    }
}


/////////////////////////////
/// CONTACT-CONTENT
 
.contact-content {
    display: flex;
    font-size: 1.8rem;
    justify-content: space-evenly;
    color: #fff;
    @media only screen and (max-width: 800px){
        font-size: 1.6rem;
    }
    @media only screen and (max-width: 600px){
        font-size: 1.4rem;
        padding-bottom: 5rem;
    }

    & h3 {
        margin-bottom: 1.8rem;
    }


    &__address {

    }

    &__openings {

    }

    &__email {
        & p {
            display: inline-block;
        }
        & div {
            display: flex;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 1rem;
            }
        }
       
    }

    &__icon {
        display: inline-block;
        margin-right: 1.5rem;
        height: 3rem;
        width: 3rem;
        fill:currentColor;
        
    }
}
