
:root {

    --color-primary: #E0212F;
    --color-secondary: lightgreen;

    --box-shadow: 0 4rem 4rem rgba(0,0,0,.4);
    --box-shadow-small: 0 2rem 2rem rgba(0,0,0,.3);
}




* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}


html {
    box-sizing: border-box;
    font-size: 62.5%;
    @media only screen and (max-width: 1200px){
        font-size: 55%;
    }
    @media only screen and (max-width: 1000px){
        font-size: 50%;
    }
    @media only screen and (max-width: 800px){
        font-size: 45%;
    }
}


body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 1.6;
    // background-color: grey;
    background-color: var(--color-primary);

}